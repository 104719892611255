@import "../variables.scss";

#realizations {
    padding: 0;
    position: relative;

    .realizations-title {
        position: absolute;
        z-index: 0;
        left: 0;
        right: 0;
        top: 5rem;
    }
}

#realizationCarousel {
    position: relative;

    .owl-carousel {
        position: relative;
        z-index: 0;
        .item {
            height: 58rem;
            background: no-repeat center;
            background-size: cover;

            .pointer {
                position: absolute;
                display: flex;
                width: 5rem;
                height: 5rem;
                background: transparent;
                z-index: 2;
            }

            .modal {
                position: absolute;
                width: 45.2rem;
                height: 18.5rem;
                @include box-shadow(0rem 1.3rem 1.65rem 0 rgba($black, 0.5));
                background-color: $white;
                top: unset;

                .realization-image {
                    width: 13.5rem;
                    height: 13.5rem;
                    @include box-shadow(0rem 1.3rem 1.65rem 0 rgba($black, 0.1));
                }

                .modal-details {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    margin: 0 2rem;
                    position: relative;
                    justify-content: space-between;
                    min-width: 0;

                    .modal-title {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        .long-title-helper {
                            text-decoration: none;
                            cursor: default;
                        }
                    }

                    .modal-info-wrapper {
                        display: flex;
                        flex-direction: column;
                        flex: 1;

                        .modal-info-item {
                            display: flex;
                            align-items: center;

                            .custom-icon {
                                margin: 0 0.5rem 0 -0.5rem;

                                &.pin_icon {
                                    margin: 0 1rem 0 0;
                                }
                            }
                        }
                    }

                    .main-button {
                        &.see-product {
                            padding: 1.2rem 2.7rem;
                            letter-spacing: 0.1rem;
                        }
                    }

                    .modal-close {
                        position: absolute;
                        right: -4.5rem;
                        top: -1.2rem;
                        width: 2.4rem;
                        height: 2.4rem;
                    }
                }

                &.show {
                    display: flex !important;
                    padding: 2.5rem 3.3rem 2.5rem 2.5rem !important;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-medium) {
    #realizationCarousel {
        .owl-carousel {
            .item {
                .pointer {
                }

                .modal {
                    .realization-image {
                        display: none;

                    }

                    .modal-details {
                        padding: 0rem 5.5rem 2rem 3rem;
                        background-color: $white;
                        margin-bottom: 6rem;

                        .modal-title {
                            line-height: 1;
                            .long-title-helper {
                                white-space: pre-line;
                                font-size: $sm-font-size;
                            }
                        }

                        .modal-info-wrapper {
                            margin: 1rem 0;
                        }

                        .main-button {

                        }

                        .modal-close {
                            right: 1.5rem;
                            top: 2rem;
                        }
                    }

                    &.show {
                        width: unset;
                        height: unset;
                        padding: 0 !important;
                        top: 0 !important;
                        left: 0 !important;
                        bottom: 0;
                        right: 0;
                        background-color: unset;
                        align-items: flex-end;
                    }
                }
            }

        }
    }
}
