@import "../variables.scss";


#blogEntries {
    background-color: $light-gray-color;
    padding: 6rem 4rem;


    .blog-entries-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
    }

    .article-wrapper {
        display: flex;
        flex-wrap: wrap;

        .article-item-link {
            display: flex;
            flex: 1;
            text-decoration: none;
            margin: 1rem 0.8rem;

            &:hover {
                margin: 0.25rem 0.1rem;
                .article-item {
                    width: 31.29rem;
                    height: 31.5rem;
                    .article-item-header {
                        padding: 2rem 1rem;
                    }
                    .article-info-footer {
                        .info {
                            &.social {
                                .social-item {
                                    .custom-icon {
                                        width: 1.89rem;
                                        height: 1.68rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .article-item {
                position: relative;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 1.5rem 2.5rem;
                height: 30rem;
                background-size: cover;

                .article-item-header {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                    padding: 1.5rem 0;
                    z-index: 10;
                    border-bottom: 2px solid rgba($white, 0.25);
                }

                .article-info-footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: $white;
                    z-index: 10;
                    opacity: 0.75;

                    .info {
                        display: flex;
                        flex: 1;

                        &.author {
                            justify-content: flex-start;
                            align-items: center;
                        }

                        &.social {
                            justify-content: flex-end;

                            .social-item {
                                display: flex;
                                align-items: center;
                                margin-left: 2rem;

                                .custom-icon {
                                    margin-right: 0.75rem;
                                    color: $gray-color;
                                }
                            }
                        }

                        .dot {
                            width: 0.5rem;
                            height: 0.5rem;
                            border-radius: 0.2rem;
                            background-color: rgba($white, 0.5);
                            margin: 0 1rem;
                        }
                    }
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: linear-gradient(0deg, rgba($black, 0.75) 20%, rgba($white, 0) 59%);
                }
            }
        }
    }
}

@media (max-width: $breakpoint-medium) {

    #blogEntries {
        padding: 5rem 0;
        .article-wrapper {
            flex-direction: column;

            .article-item-link {
                margin: 0.5rem 0;
                &:hover {
                    transform: none;
                }
                .article-item {
                    .article-info-footer {
                        flex-wrap: wrap;

                        .info {
                            &.author, &.social {
                                justify-content: center;
                            }

                        }
                    }
                }
            }
        }
    }
}
