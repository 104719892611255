@import "../variables";
@import "header";
@import "footer";
@import "realization";
@import "reasons";
@import "similarProducts";
@import "blogEntries";

body {
    background: $white;
    font-family: "Roboto", sans-serif;
    padding: 0 !important;

    &.modal-open {
        overflow: unset;
    }

    main {
        display: flex;
        flex-direction: column;
        margin: 0;
        min-height: 60vh;
        margin-top: 20vh;

        #errorSection {
            height: calc(100vh - 24.1rem);
            justify-content: space-evenly;
            align-items: center;

            .logo {
                width: 45rem;
            }

            .error-message {
                display: flex;
                flex-direction: column;
            }
        }

        .alert-container {
            position: absolute;
            top: 20rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: none;
            left: 0;
            right: 0;
            z-index: 200;

            .alert.alert-dismissible {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $light-gray-color;
                color: $dark-gray-color;

                &.alert-danger {
                    color: $red-orange-color;
                }
            }
        }


        .no-results {
            display: flex;
            justify-content: center;
            margin-bottom: 4rem;

            .message {
                .content {
                    p {
                        font-size: $ml-font-size;
                        padding: 5rem;
                        color: $gray-color;
                        background-color: $lightest-gray-color;
                        border: thin solid $light-gray-color;
                        font-weight: 700;
                    }
                }
            }

        }
    }

    button {
        &:focus {
            outline: none;
        }
    }
}

@media (max-width: $breakpoint-large) {
    body {
        main {
            margin-top: 6rem;

            #errorSection {
                .logo {
                    width: 30rem;
                }

                h1 {
                    font-size: $xxl-font-size;

                }
            }
        }
    }

}
