// Normalize :
html,
body,
div,
span,
applet,
object,
iframe,
blockquote,
pre,
a,
acronym,
address,
button,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    color: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    font-family: Roboto-medium, sans-serif;
    line-height: 1;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button::-moz-focus-inner {
    border: 0;
}
:focus {
    outline: none;
}

p {
    border: 0;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    text-align: unset;
}

// box sizing settings:
* {
    &,
    &::before,
    &::after {
        box-sizing: border-box;
    }
}


// native HTML5 tags normalizing:

select {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: inherit;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: none;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    -webkit-appearance: unset;
    box-sizing: border-box;
    align-items: unset;
    white-space: unset;
    -webkit-rtl-ordering: unset;
    background-color: unset;
    cursor: default;
    margin: unset;
    font: inherit;
    border-radius: unset;
    border-width: unset;
    border-style: unset;
    border-color: unset;
    border-image: unset;
}

button {
    -webkit-appearance: unset;
    text-rendering: auto;
    color: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: unset;
    text-indent: unset;
    text-shadow: unset;
    display: unset;
    text-align: unset;
    text-align-last: center;
    -moz-text-align-last: center;
    align-items: unset;
    cursor: unset;
    background-color: unset;
    box-sizing: border-box;
    margin: unset;
    font: unset;
    padding: unset;
    border-width: unset;
    border-style: unset;
    border-color: unset;
    border-image: unset;
}

input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: unset;
    color: unset;
    letter-spacing: unset;
    word-spacing: unset;
    text-transform: none;
    text-indent: unset;
    text-shadow: none;
    display: unset;
    text-align: unset;
    -webkit-appearance: unset;
    background-color: unset;
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: unset;
    font: unset;
    padding: unset;
    border-width: unset;
    border-style: unset;
    border-color: unset;
    border-image: unset;
}


// reset bootstrap class:

.main-content > :first-child:not(nav):not(.nav) {
    margin: 0 auto;
}
