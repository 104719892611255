@import '../variables.scss';

#header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: $white;

    .container {
        .menu-wrapper {
            flex: 1;
            gap: 1rem;
            margin-top: 2rem;
            margin-bottom: 2rem;

            .navbar-top {
                flex: 1;

                .logo-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 6rem;

                    a {
                        display: flex;

                        .logo {
                            width: 25rem;
                        }
                    }
                }

                .menu-secondary {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    gap: 1rem;

                    .menu-top {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        margin: 0 -1.5rem;

                        :last-child {
                            &::after {
                                display: none;
                            }
                        }
                        .nav-item {
                            display: flex;
                            align-items: center;
                            position: relative;
                            word-wrap: normal;
                            &.shown {
                                .dropdown-menu {
                                    &.shown {
                                        display: block;
                                    }
                                }
                            }
                            .dropdown-toggle::after {
                                display: none;
                            }
                            &::after {
                                content: '';
                                position: absolute;
                                right: 0;
                                top: calc(50% - 0.7rem);
                                bottom: 0;
                                width: 0.1rem;
                                height: 1.4rem;
                                background: $divider-color;
                            }
                            .nav-link {
                                padding: 0 1.5rem;
                                font-size: $base-font-size;
                                color: $gray-color;
                                letter-spacing: 0.14rem;
                                font-weight: 500;
                                text-transform: lowercase;
                                &:hover {
                                    color: $red-orange-color;
                                }
                            }
                        }
                    }

                    .menu-actions {
                        display: flex;
                        justify-content: flex-end;
                        align-items: stretch;
                        gap: 1rem;
                        height: 6rem;

                        #search_dropdown {
                            align-items: center;
                            .dropdown-toggle {
                                &::after {
                                    display: none;
                                }
                                .custom-icon.search {
                                    width: 2.4rem;
                                    height: 2.4rem;
                                    background: url('../../images/icons/search_icon.png') no-repeat center;
                                    background-size: contain;
                                }
                            }
                            .dropdown-menu {
                                right: 100%;
                                top: 0.25rem;
                                padding: 0.8rem;
                                margin: 0;
                                border: none;
                                background-color: $light-gray-color;
                                min-width: 35rem;
                                z-index: 100;
                                box-shadow: 0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.2);
                                .search-form-wrapper {
                                    background-color: $white;
                                    .search-input {
                                        padding: 0.5rem 1.5rem;
                                        font-size: $base-font-size;
                                        &::placeholder {
                                            font-size: $base-font-size;
                                        }
                                    }
                                    .btn.btn-primary {
                                        padding: 0.5rem 2.7rem 0.5rem 1.6rem;
                                        font-size: $small-font-size;
                                        color: $white;
                                        border: 0;
                                        background-color: $red-orange-color;
                                        font-weight: 600;
                                        position: relative;
                                        &::after {
                                            content: '';
                                            display: flex;
                                            height: 1.2rem;
                                            width: 1.2rem;
                                            background: url('../../images/icons/chevron-right-white.svg') no-repeat center;
                                            color: $white;
                                            position: absolute;
                                            top: calc(50% - 0.6rem);
                                            right: 0.8rem;
                                            border: none;
                                        }
                                    }
                                }
                            }
                        }

                        .btn-group {
                            .dropdown-toggle {
                                font-size: $base-font-size;
                                font-weight: 500;
                                color: $divider-color;

                                &::after {
                                    display: none;
                                }

                                .custom-icon.cart {
                                    margin: 0 0.75rem;
                                    width: 2.2rem;
                                    height: 2.2rem;
                                    background: url('../../images/icons/basket_icon.png') no-repeat center;
                                }
                                .cart-order-slash {
                                    margin: 0 0.5rem;
                                }
                                .cart-order-total {
                                    color: $red-orange-color;
                                }
                            }

                            .dropdown-menu.cart-menu {
                                flex-direction: column;
                                top: 7rem;
                                color: $gray-color;
                                font-size: $small-font-size;
                                border-radius: 0;
                                width: 26.2rem;
                                background-color: $white;
                                position: absolute;
                                right: -2rem;
                                padding: 1.5rem;
                                left: unset;
                                z-index: 9999;

                                .table {
                                    color: $gray-color;

                                    td {
                                        padding: 1.5rem;
                                    }

                                    .summary {
                                        font-size: $base-font-size;
                                        font-weight: 700;
                                    }
                                }

                                .button-wrapper {
                                    flex-direction: column;
                                    align-items: stretch;
                                    margin: 1rem 0 0 0;

                                    .btn {
                                        color: $gray-color;
                                        border-color: $gray-color;
                                        font-size: $small-font-size;

                                        &:hover {
                                            background-color: $light-gray-color;
                                        }
                                    }

                                    .btn-primary {
                                        background-color: $light-gray-color;

                                        &:hover {
                                            background-color: $medium-gray-color;
                                        }
                                    }

                                    .main-button {
                                        box-shadow: none;

                                        &:first-child {
                                            margin-bottom: 1rem;
                                        }
                                    }
                                }
                            }
                            .custom-icon.instagram {
                                width: 2.4rem;
                                height: 2.4rem;
                                background: url('../../images/icons/instagram_icon.png') no-repeat center;
                            }
                            .custom-icon.facebook {
                                width: 1.8rem;
                                height: 1.8rem;
                                background: url('../../images/icons/facebook_icon.png') no-repeat center;
                            }
                        }

                        .vertical-divider {
                            position: relative;
                            &::after {
                                content: '';
                                position: absolute;
                                right: 0;
                                top: calc(50% - 0.7rem);
                                bottom: 0;
                                width: 0.1rem;
                                height: 1.4rem;
                                background: $divider-color;
                            }
                        }
                    }

                    .menu-mobile-icon {
                        display: none;
                    }
                }
            }

            .navbar-categories {
                display: flex;
                justify-content: center;
                align-items: stretch;
                flex: 1;

                .main-navbar {
                    align-items: stretch;

                    &.menu-categories {
                        background-color: $white;
                        @include box-shadow(0 0.4rem 1.5rem 0 rgba($black, 0.2));

                        .navbar-nav {
                            .menu-categories-main {
                                display: flex;
                                box-sizing: border-box;
                                border-bottom: 0.3rem solid transparent;
                                position: relative;
                                margin-top: 0.3rem;
                                padding: 0;

                                &.show {
                                    border-bottom: 0.3rem solid $red-orange-color;

                                    .nav-link {
                                        color: $red-orange-color;
                                        &::after {
                                            background: url('../../images/icons/chevron_right_active.png') no-repeat center;
                                        }
                                    }

                                    .dropdown-menu {
                                        display: flex;

                                        &.dropdown-submenu {
                                            display: none;
                                        }
                                        .nav-item {
                                            &.with-submenu {
                                                &.show .dropdown-menu.dropdown-submenu {
                                                    display: block;
                                                }
                                            }
                                        }
                                    }
                                }

                                &:hover:not(.account-options) {
                                    border-bottom: 0.3rem solid $red-orange-color;

                                    .nav-link {
                                        color: $red-orange-color;
                                        &::after {
                                            background: url('../../images/icons/chevron_right_active.png') no-repeat center;
                                        }
                                    }

                                    .dropdown-menu {
                                        display: flex;

                                        &.dropdown-submenu {
                                            display: none;
                                        }
                                        .nav-item {
                                            &.with-submenu {
                                                &:hover .dropdown-menu.dropdown-submenu {
                                                    display: block;
                                                }
                                            }
                                        }
                                    }
                                }
                                .nav-link {
                                    padding: 2.7rem 3.6rem 2.7rem 2rem;
                                    font-size: $base-font-size;
                                    color: $gray-color;
                                    letter-spacing: 0.14rem;
                                    font-weight: 500;
                                    text-align: center;
                                    position: relative;
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }
                    }

                    .navbar-nav {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-around;
                        list-style: none;
                        padding-left: 0;
                    }
                }

                .nav-item {
                    position: relative;
                    .dropdown-menu-list {
                        width: 20rem;
                        background-color: $white;
                    }
                    &.dropdown {
                        .dropdown-menu {
                            display: none;
                            position: absolute;
                            top: calc(100% + 0.3rem);
                            left: 0;
                            padding: 0;
                            margin: 0;
                            border: none;
                            padding-top: 0.3rem;
                            background-color: transparent;
                            &.show {
                                display: block;
                            }
                            &.producers {
                                background-color: $white;
                            }

                            .dropdown-item {
                                white-space: nowrap;
                                align-items: center;
                                padding: 0.85rem 1.8rem;
                                letter-spacing: 0;
                                line-height: 1.25;
                                font-size: $base-font-size;
                                color: $gray-color;
                                font-weight: 600;

                                &:not(.last-item)::after {
                                    content: '';
                                    display: flex;
                                    height: 1rem;
                                    width: 1rem;
                                    background: url('../../images/icons/chevron_right.png') no-repeat center;
                                    border-radius: 4px;
                                    position: absolute;
                                    top: calc(50% - 0.5rem);
                                    right: 1.5rem;
                                    padding: 0.25rem;
                                    border: none;
                                }
                                &.single {
                                    &:nth-child(odd) {
                                        background-color: $light-gray-color;
                                    }
                                    &::after {
                                        display: none;
                                    }
                                }
                                &:hover,
                                &:focus,
                                &:active {
                                    color: $red-orange-color;
                                }
                            }
                        }

                        &:hover .dropdown-menu {
                            display: block;
                            box-shadow: 0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.2);
                        }
                    }

                    &.with-submenu {
                        &:nth-child(odd) {
                            background-color: $light-gray-color;
                        }

                        .dropdown-submenu {
                            display: none;
                            position: absolute;
                            top: 0;
                            left: 100%;
                            background-color: $white;
                            border: 0;
                            box-shadow: 0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.2);
                            li:nth-child(odd) {
                                background-color: $light-gray-color;
                            }
                            .dropdown-item {
                                &::after {
                                    display: none;
                                }
                            }
                        }

                        &:hover .dropdown-submenu {
                            display: block;
                        }
                    }

                    .nav-link {
                        &:after {
                            content: '';
                            display: flex;
                            height: 1rem;
                            width: 1rem;
                            background: url('../../images/icons/chevron_right.png') no-repeat center;
                            border-radius: 0.4rem;
                            position: absolute;
                            top: calc(50% - 0.5rem);
                            right: 1.6rem;
                            padding: 0.25rem;
                            border: none;
                        }
                    }
                }
            }
        }
    }

    .dropdown-submenu .dropdown-item {
        &::after {
            display: none !important;
        }
    }
}

@media (max-width: $breakpoint-full) {
    #header {
        .container {
            .menu-wrapper {
                margin: 2rem 0;
                .navbar {
                    .main-navbar {
                        &.menu-categories {
                            .navbar-nav {
                                .menu-categories-main {
                                    .nav-link {
                                        padding: 2.2rem 3.2rem 2.2rem 1.8rem;
                                        font-size: $small-base-font-size;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-xlarge) {
    #header {
        .container {
            .menu-wrapper {
                .navbar {
                    .main-navbar {
                        &.menu-categories {
                            .navbar-nav {
                                .menu-categories-main {
                                    .nav-link {
                                        padding: 2rem 2rem 2rem 1.4rem;
                                        font-size: $xs-font-size;

                                        &::after {
                                            right: 0.5rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-large) {
    #header {
        position: fixed;
        height: unset;
        background-color: #fff;
        -webkit-box-shadow: 1rem 1rem 3rem -1rem rgba(0, 0, 0, .5);
        -moz-box-shadow: 1rem 1rem 3rem -1rem rgba(0, 0, 0, .5);
        -o-box-shadow: 1rem 1rem 3rem -1rem rgba(0, 0, 0, .5);
        box-shadow: 1rem 1rem 3rem -1rem rgba(0, 0, 0, .5);

        .container {
            padding: 0;
            margin: 0;

            .menu-wrapper {
                flex-direction: column;
                flex-wrap: nowrap;
                height: 6rem;
                gap: 0;
                margin: 0;
                padding: 0;

                .navbar-top {
                    flex-wrap: nowrap;

                    .logo-wrapper {
                        padding: 0 1rem;
                        a {
                            .logo {
                                width: 20rem;
                            }
                        }
                    }

                    .menu-secondary {
                        flex-direction: row;
                        justify-content: flex-end;

                        .menu-top {
                            display: none;
                        }

                        .menu-actions {
                            .search-container,
                            .vertical-divider,
                            .social-icons {
                                display: none;
                            }
                        }

                        .menu-mobile-icon {
                            display: flex;

                            .navbar-toggler {
                                height: 6rem;
                                padding: 2rem;
                            }
                        }

                    }
                }

                .navbar-categories {
                    height: calc(100vh - 10rem);

                    .main-navbar {
                        &.menu-categories {
                            flex: unset;
                            width: 100%;
                            overflow: scroll;
                            max-height: 100%;

                            .navbar-nav {
                                flex-direction: column;

                                .nav-item.menu-categories-main {
                                    flex-direction: column;
                                    margin: 0;
                                    background: transparent!important;
                                    border: none!important;

                                    &:nth-child(odd) {
                                        background: $light-gray-color!important;
                                    }

                                    &:hover {
                                        border: none!important;
                                    }

                                    .dropdown-menu {
                                        display: flex;
                                        position: relative;
                                        border: none;
                                        padding: 0;
                                        box-shadow: none !important;

                                        &.dropdown-submenu {
                                            display: none;
                                        }

                                        .nav-item {
                                            &.with-submenu {
                                                &.show .dropdown-menu.dropdown-submenu {
                                                    display: flex;
                                                    flex-direction: column;
                                                    left: 0;
                                                }
                                            }
                                        }

                                        .dropdown-menu-list {
                                            width: 100%;
                                            background: transparent;

                                            .dropdown-item {
                                                padding: 2rem 2rem 2rem 3.6rem;
                                                background: transparent!important;

                                                &:after {
                                                    right: 2.2rem;
                                                    background-size: 160%;
                                                }

                                                &:hover {
                                                    &:after {
                                                        background-size: 160%;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .nav-link {
                                        width: 100%;
                                        font-size: $base-font-size;

                                        &:after {
                                            right: 2.2rem;
                                            background-size: 160%;
                                        }

                                        &:hover {
                                            &:after {
                                                background-size: 160%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #menu-contact-mobile {
        display: flex;
        align-items: center;
        height: 5rem;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        background: $red-orange-color;

        a {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            color: $white;
            font-size: $base-font-size;

            span {
                padding-left: 2rem;
                &.phone {
                    background: url('../../images/icons/phone_icon_white.svg') no-repeat left center;
                    background-size: 1.2rem;
                }

                &.email {
                    background: url('../../images/icons/email_icon_white.svg') no-repeat left center;
                    background-size: 1.2rem;
                }
            }
        }

        .divider {
            height: 2rem;
            width: 1px;
            background: $white;
            opacity: 0.4;
        }
    }
}
