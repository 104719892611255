@import "../variables";

#mainFooter {
    display: flex;
    color: $footer-font-color;
    font-size: $small-base-font-size;
    font-weight: normal;
    letter-spacing: 0.1rem;
    margin: 0;
    background-color: $dark-color;

    .container {
        padding: 4rem;

        .row {
            .footer-column {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding-left: 1.5rem;
                padding-right: 1.5rem;

                &.logo-container {
                    justify-content: space-between;

                    .logo-wrapper {
                        .logo {
                            width: 25rem;
                            background-color: transparent;
                        }
                    }

                    .copyright {
                        font-size: $small-font-size;
                        letter-spacing: 0.1rem;
                        font-weight: 700;
                    }
                }

                .bitbag-block {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 3rem;

                    h2, p {
                        color: $footer-font-color;
                        font-weight: 700;
                        margin-bottom: 0.75rem;
                        font-size: $base-font-size;
                        text-align: unset;
                    }

                    p {
                        margin-bottom: 0;
                        font-weight: 500;
                        font-size: $small-base-font-size;
                    }

                    &:last-child {
                        margin: 0;
                    }
                }

                ul {
                    li {
                        margin-bottom: 1rem;
                        font-weight: 500;
                    }
                }


                .facebook-link {
                    position: relative;
                    padding: 0.5rem 2rem 0;
                    font-weight: 700;
                    font-size: $base-font-size;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        width: 1rem;
                        background: url('../../images/icons/facebook_icon.svg') no-repeat center;
                        background-size: contain;

                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-medium) {
    #mainFooter {
        .container {
            .row {
                flex-direction: column;
                align-items: flex-start;
                .footer-column {
                    align-items: flex-start;
                    margin-bottom: 2rem;

                    .logo-wrapper {
                        margin-bottom: 2rem;
                    }

                    .bitbag-block {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    &.logo-container, &.links {
                        justify-content: center;

                    }

                    .facebook-link {
                        display: flex;
                        align-self: flex-start;
                    }

                }
            }
        }
    }
}

@media (max-width: $breakpoint-small) {
    #mainFooter {
        .container {
            .row {
                .footer-column.logo-container {
                    .logo-wrapper {
                        .logo {
                            width: 20rem;
                        }
                    }
                }
            }
        }
    }
}
