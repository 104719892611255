@import "../variables.scss";

#whyGlatz {
    background-color: $light-gray-color;
    padding-top: 6rem;
    padding-bottom: 5rem;

    h2 {
        margin: 0 0 2rem;
    }

    .why-glatz-title {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: flex-end;
    }
    #whyGlatzCarouselMobile {
        display: none;
    }
    .reason-tiles-wrapper {
        display: flex;
        padding: 2rem 2.8rem;

        .reason-tile {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            position: relative;
            background: rgba($medium-gray-color, 0.1);
            margin: 0 0.5rem;
            padding: 2.5rem 1rem;

            .reason-icon {

            }

            .reason-tile-title {
                margin: 1.75rem 0 0.75rem 0;
                font-size: $sm-font-size;
            }

            .reason-tile-description {
                flex: 1;
                margin-bottom: 2rem;
                letter-spacing: 0;
                font-size: $small-base-font-size;
            }

            .reason-tile-button {
                display: none;

                position: absolute;
                bottom: -2rem;
                padding: 1.3rem 3.7rem;
            }

            &:hover {
                background: $lightest-gray-color;;

                .reason-tile-button {
                    display: flex;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-medium) {
    #whyGlatz {
        height: unset;
        padding-top: 3rem;
        padding-bottom: 0;

        .reason-tiles-wrapper {
            display: none;
            flex-direction: column;
            text-align: center;
        }

        #whyGlatzCarouselMobile {
            display: block;
            position: relative;

            .owl-carousel {
                .owl-item {
                    margin-bottom: 7rem;
                    .reason-tile {
                        @include box-shadow(0rem 1rem 1.35rem 0 rgba($black, 0.1));
                        background-color: $white;
                        padding: 3rem;
                        margin: 0 1rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .reason-tile-title {
                            margin: 1rem 0;
                        }
                        .reason-tile-description {
                            font-size: $small-base-font-size;
                            letter-spacing: unset;
                        }
                        .reason-tile-button {
                            justify-content: center;
                            color: $red-orange-color;
                            background-color: $white;
                            box-shadow: none;
                            padding: 1.3rem 4rem 1.3rem 2.2rem;
                            &:after {
                                background-image: url('../../images/icons/next_icon.svg');

                            }
                        }
                    }
                }
            }

            .slide-progress {
                display: none;
            }
        }
    }
}
