@import "../variables.scss";

.product-list-section {
    background: $light-gray-color;
    padding-bottom: 6rem;
    align-items: center;

    h2 {
        margin-top: 4rem;
    }

    &.associated-product-listing {
        background-color: transparent;
        align-items: stretch;
    }

    .products-wrapper {
        display: flex;
        flex: 1;
        padding: 3rem 0;
        position: relative;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .product-item {
            display: flex;
            margin: 2rem 1rem;
            width: 24rem;
            @include box-shadow(0 1.3rem 1.3rem rgba($black, 0.1));
            background: $white;
            transition: all 0.25s linear;

            &:hover {
                transform: scale(1.1);
            }

            .product-item-link {
                padding: 2rem;
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                position: relative;
                margin-bottom: 0;

                .product-image-container {
                    .product-image {
                        width: 100%;
                        max-height: 15rem;
                    }
                }

                .product-header {
                    display: flex;
                    flex: 1;
                    text-transform: uppercase;
                    align-items: center;
                    justify-content: center;
                    margin: 0.5rem 0;
                }
                .product-options-list {
                    font-size: $base-font-size;
                    color: $dark-gray-color;
                    margin: 0.5rem 0 2rem;
                    width: 100%;

                    li {
                        margin: 0.5rem 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &.color-option {
                            .option-value {
                                color: $white;
                                justify-content: center;
                                align-items: center;
                                font-weight: 700;
                                text-align: center;
                                padding: 0.5rem 1.5rem;
                            }
                        }
                    }
                }
                .sylius-product-price {
                    font-size: $base-font-size;
                    font-weight: 600;
                    color: $dark-gray-color;
                }
                .main-button {
                    margin: 1rem 0;
                }
            }
        }
    }

    .pagination {
        align-self: center;
        margin: 2rem;
        font-size: $base-font-size;
    }
}

@media (max-width: $breakpoint-medium) {
    .product-list-section {
        .products-wrapper {
            flex-wrap: wrap;
            justify-content: stretch;
            padding: 2rem 0.5rem;
            flex: 1;

            .product-item {
                width: unset;
                height: unset;
                flex: 1;
                justify-content: center;

                .product-item-link {
                    margin: 0;
                    align-items: center;
                }
            }
        }
    }
}
