@import "variables";

#cookie-law-info-bar {
    background: $lightest-gray-color !important;
    bottom: 0 !important;
    z-index: 99999;
}

#cookie-law-close-button {
    background-color: $red-orange-color !important;
    color: $black !important;
}

.formatted-text-content, .preformatted-text {
    ol, ul {
        margin-left: 4rem;
        font-size: $small-font-size;
        color: $dark-gray-color;
        line-height: 2.2rem;
        letter-spacing: 0.1rem;
        font-weight: 400;

        li, span {
            line-height: 2.2rem;
        }
    }

    ul {
        list-style-type: circle;
    }

    ol {
        list-style-type: decimal;
    }

    p {
        padding: 0;
    }

    table {
        border: 1px solid $bootstrap-header-color;

        &[align="center"] {
            margin-left: auto;
            margin-right: auto;
        }

        td, th {
            border: 1px solid $bootstrap-header-color;
        }
    }

    a {
        text-decoration: underline;
    }
}

#regulamin .formatted-text-content, .preformatted-text {
    ol {
        li {
            font-size: 1.5rem;
        }
    }
}


.bitbag-block {
    h2 {
        font-size: $sm-font-size;
        line-height: unset;
        text-align: unset;
    }

    p {
        font-size: $base-font-size;
        letter-spacing: 0.1rem;
        line-height: 2.2rem;
        color: rgba(#fff, 0.5);
        text-align: unset;
    }
}

a:hover {
    color: $red-orange-color;
    text-decoration: none;
}

h1 {
    font-size: 5.5rem;
    font-weight: 700;
    text-align: center;

    &.white {
        color: $white;
        filter: drop-shadow(0 0 92.5px rgba(35, 20, 14, 0.53));
    }
}

h2 {
    font-size: 3.5rem;
    font-weight: 700;
    text-align: center;

    &.light {
        color: $white;
    }

    &.dark {
        color: $dark-color;
    }
}

h3 {
    font-size: $m-font-size;
    letter-spacing: 0.1rem;
    color: $dark-gray-color;
    font-weight: 700;
    text-align: center;

    &.article-item-header {
        line-height: 2.6rem;
        font-size: $l-font-size;
        color: $white;
        filter: drop-shadow(0px 0.7rem 0.35rem rgba(0, 0, 0, 0.15));
    }

    &.medium-size {
        font-size: $ml-font-size;
    }

    &.base-font-size {
        font-size: $base-font-size;
    }

    &.sm-font-size {
        font-size: $sm-font-size;
    }
}

p {
    font-size: $small-font-size;
    color: $dark-gray-color;
    line-height: 2.2rem;
    letter-spacing: 0.1rem;
    font-weight: 400;
    text-align: center;

    &.very-small {
        font-size: $xs-font-size;
        font-weight: 500;
    }

    &.small-base {
        font-size: $small-base-font-size;
    }

    &.bold {
        font-weight: 700;
    }

    &.medium {
        font-size: $base-font-size;
    }

    &.sm-font-size {
        font-size: $sm-font-size;
    }

    &.dark {
        color: $dark-color;
    }

    &.light {
        color: $white;
    }
}

.container {
    max-width: 1366px;
}

.btn {
    &:hover,
    &:focus {
        box-shadow: none;
    }
}

.main-button {
    display: flex;
    color: $white;
    font-size: $base-font-size;
    font-weight: 700;
    position: relative;
    @include box-shadow(0rem 1rem 1.35rem 0 rgba($black, 0.1));
    background-color: $red-orange-color;
    padding: 1.3rem 5rem 1.3rem 2.2rem;
    align-items: center;
    letter-spacing: 0.1rem;
    text-decoration: none;

    &::after {
        content: "";
        color: $white;
        display: flex;
        height: 1rem;
        width: 0.7rem;
        background-size: auto;
        background-image: url('../images/icons/arrow_icon.svg');
        border-radius: 0.4rem;
        position: absolute;
        top: calc(50% - 0.5rem);
        right: 2.4rem;
        padding: 0.25rem;
    }

    &:disabled {
        opacity: 0.5;
    }

    &.centered {
        justify-content: center;
    }

    &.trash {
        padding: 1.5rem 1.5rem 1.5rem 4rem;
        margin: 0;
        font-size: 1.2rem;

        &::after {
            height: 1.3rem;
            width: 1.3rem;
            top: calc(50% - 0.75rem);
            left: 1.4rem;
            right: unset;
            margin-right: 1rem;
            background-image: url(../images/icons/trash_icon.svg);
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }
    }

    &.outline {
        color: $red-orange-color;
        border: thin solid $red-orange-color;
        background-color: $white;

        &:hover {
            color: $white;
            background-color: $red-orange-color;
        }
    }

    &.basket {
        padding: 1.5rem;
        margin: 0;
        font-size: $small-font-size;

        &:after {
            display: none;
        }
    }

    &.dashboard {
        &:after {
            display: none;
        }

        .button-content {
            margin: 0 1rem;
        }
    }

    &:hover {
        text-decoration: none;
        color: $white;
    }

    &.hoverable {
        &:hover {
            color: $white;
            font-size: $base-m-font-size;
            letter-spacing: 0.2rem;
            padding: 1.45rem 5.4rem 1.45rem 2.3rem;
        }
    }
}

// cart table wrapper common styles:

.cart-table-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;

    .cart-table {
        &.basket-table {
            border-color: transparent;

            .cart-header {
                background-color: $light-gray-color;
                color: $dark-gray-color;
                font-size: $base-font-size;
                font-weight: 700;
                padding: 1.7rem;
                white-space: nowrap;
            }

            .cart-table-body, .cart-table-footer {

                .basket-item-wrapper {
                    font-size: $small-base-font-size;
                    color: $gray-color;
                    background-color: $white;
                    border-bottom: thin solid $light-gray-color;

                    &.totals {
                        .summary-row {
                            padding: 1rem;

                            &.total {
                                font-weight: 700;
                                color: $dark-gray-color;
                                font-size: $base-font-size;
                            }

                            .summary-item {
                                display: flex;
                                flex: 1;
                                justify-content: space-between;
                                align-items: center;
                            }
                        }
                    }

                    .item-cell {
                        text-align: center;
                        vertical-align: middle;

                        &.subtotal {
                            font-weight: 700;
                            color: $dark-gray-color;
                        }
                    }

                    .item-details-wrapper {
                        .item-details {
                            display: flex;
                            color: $gray-color;
                            padding: 0 1rem;
                            justify-content: space-evenly;

                            &.mobile-details {
                                display: block;
                            }

                            .item-name {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                font-size: $base-font-size;
                                flex: 1 1 0;
                            }

                            .item-image {
                                display: flex;
                                padding: 1rem;
                                flex: 1 1 0;
                                justify-content: center;


                                .product-image-gallery-wrapper {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    .product-image-container {
                                        .product-image {
                                            max-width: 10rem;
                                            max-height: 10rem;
                                        }
                                    }

                                    .popup-toggler {
                                        display: none;
                                    }

                                    .product-modal {
                                        display: none;
                                    }
                                }
                            }

                            .item-description {
                                font-size: $base-font-size;
                                padding: 0.5rem;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-evenly;

                                .item-code {
                                    color: $dark-gray-color;
                                    font-weight: 600;
                                }

                                .options-details {
                                    position: relative;

                                    .summary-details {
                                        font-weight: 700;
                                    }

                                    .summary-list {
                                        display: flex;
                                        flex-direction: column;
                                        flex: 1;
                                        position: absolute;
                                        font-size: $small-font-size;
                                        font-weight: normal;
                                        margin: 1rem 0;
                                        background-color: $light-gray-color;
                                        z-index: 200;
                                        border: thin solid rgba($black, 0.125);

                                        .summary-list-item:nth-of-type(odd) {
                                            background-color: $lightest-gray-color;
                                        }

                                        .summary-list-item {
                                            display: flex;
                                            padding: 0.75rem;
                                            justify-content: space-between;
                                            width: 35rem;

                                            .list-item-category {
                                                display: flex;
                                                flex: 1;
                                                font-weight: 700;
                                            }

                                            .list-item-value {
                                                display: flex;
                                                flex: 1;
                                                align-items: flex-start;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// delivery options wrapper common styles :

.delivery-options-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 2rem;

    .addresses-wrapper {
        display: flex;
        flex: 1;
        flex-wrap: wrap;

        .addresses-title {
            font-size: $m-font-size;
            line-height: unset;
            margin: 1rem 0;
            width: 100%;
        }
    }
}

.address-card-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;

    .address-card-header {
        background-color: $light-gray-color;
        color: $dark-gray-color;
        font-size: $base-font-size;
        font-weight: 700;
        padding: 1rem;
        white-space: nowrap;
    }

    .address-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        font-size: $small-base-font-size;
        color: $gray-color;
        background-color: $white;

        .address-item {
            display: flex;
            height: 2.3rem;
            padding: 0.5rem 1rem;

            &:nth-of-type(odd) {
                background-color: $light-gray-color;
            }
        }
    }
}

.details-options-wrapper {
    display: none;
    flex: 1;
    flex-direction: column;

    .details-options-title {
        font-size: $m-font-size;
        line-height: unset;
        margin: 1rem 0;
    }

    .details-options-content {
        display: flex;
        flex: 1;
        justify-content: space-between;

        .detail-option-list {
            display: flex;
            flex: 1;
            margin: 1rem;
            flex-direction: column;

            .detail-option-item {
                display: flex;
                margin-bottom: 0.5rem;
                background-color: $white;

                .option-item-body {
                    display: flex;
                    flex: 1 1 auto;
                    min-height: 0.1rem;
                    padding: 1.25rem;
                    align-items: center;
                    font-size: $small-font-size;
                    color: $gray-color;

                    .option-item-icon {
                        font-size: $ml-font-size;
                        padding-right: 1rem;
                    }

                    .option-item-content {
                        .price {
                            font-weight: bold;
                        }
                    }

                }

            }
        }
    }
}

// section common settings:

body {
    section {
        display: flex;
        position: relative;

        &.vertical {
            flex-direction: column;
        }

        .scroll-page-button {
            display: flex;
            position: absolute;
            bottom: 2rem;
            left: 50%;
        }
    }
}

// form control common styles:

.form-group-wrapper {
    display: flex;
    flex: 1;
    margin: 0.5rem;
    flex-wrap: wrap;

    .form-custom-label {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        font-size: $base-font-size;
        letter-spacing: 0.1rem;
        line-height: 1.6;
        color: $gray-color;
        font-weight: 700;
        align-items: center;
        position: relative;

        .label-value {
            display: flex;
            padding-right: 1rem;

            &.required {
                position: relative;

                &:after {
                    position: absolute;
                    content: '*';
                    display: inline-block;
                    padding-left: 0.4rem;
                    color: #dc3545;
                    top: 0;
                    right: 0;
                }
            }
        }

        .invalid-feedback {
            position: absolute;
            border: thin solid $lightest-gray-color;
            background-color: $white;
            left: -25rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .error-wrapper {
                display: flex;
                flex-direction: column;

                &:last-child {
                    text-align: center;
                }
            }
        }
    }

    .form-custom-input {
        display: flex;
        flex: 3;
        height: 4rem;
        font-size: $base-font-size;
        letter-spacing: 0.1rem;
        color: $gray-color;
        align-items: center;
        justify-content: center;
        outline: none;
        border: thin solid rgba($medium-gray-color, 0.5);
        text-align: left;
        margin: 0 1rem;
        padding: 0 1rem;
        font-weight: 700;
        background-color: $white;

    }

    .form-check {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $gray-color;
        font-size: $small-font-size;

        .form-check-label {
            font-weight: 700;
            padding: 1rem;

            &:after {
                display: none;
                content: '';
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
                background: url('../images/icons/checked_icon.svg') no-repeat center;
            }
        }

        .form-check-input {
            display: flex;
            margin-bottom: 0.5rem;
            align-items: center;
            border: thin solid $gray-color;
            padding: 0.7rem;
            margin-right: 1rem;
            cursor: auto;
            position: relative;
            border-radius: 0.25rem;

            &:checked {
                &:after {
                    position: absolute;
                    content: '';
                    display: block;
                    padding: 0.5rem;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: url('../images/icons/checked_icon.svg') no-repeat center;
                }
            }
        }
    }
}

.button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 2rem;
}


// form control common styles:

.form-group-wrapper {
    display: flex;
    flex: 1;
    margin: 0.5rem;
    flex-wrap: wrap;

    .form-custom-label {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        font-size: $base-font-size;
        letter-spacing: 0.1rem;
        line-height: 1.6;
        color: $gray-color;
        font-weight: 700;
        align-items: center;
        position: relative;

        .label-value {
            display: flex;
            padding-right: 1rem;

            &.required {
                position: relative;

                &:after {
                    position: absolute;
                    content: '*';
                    display: inline-block;
                    padding-left: 0.4rem;
                    color: #dc3545;
                    top: 0;
                    right: 0;
                }
            }
        }

        .invalid-feedback {
            position: absolute;
            border: thin solid $lightest-gray-color;
            background-color: $white;
            left: -25rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .error-wrapper {
                display: flex;
                flex-direction: column;

                &:last-child {
                    text-align: center;
                }
            }
        }
    }

    .form-custom-input {
        display: flex;
        flex: 3;
        height: 4rem;
        font-size: $base-font-size;
        letter-spacing: 0.1rem;
        color: $gray-color;
        align-items: center;
        justify-content: center;
        outline: none;
        border: thin solid rgba($medium-gray-color, 0.5);
        text-align: left;
        margin: 0 1rem;
        padding: 0 1rem;
        font-weight: 700;
        background-color: $white;

    }

    .form-check {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $gray-color;
        font-size: $small-font-size;

        .form-check-label {
            font-weight: 700;
            padding: 1rem;

            &:after {
                display: none;
                content: '';
                width: 1rem;
                height: 1rem;
                margin-left: 1rem;
                background: url('../images/icons/checked_icon.svg') no-repeat center;
            }
        }

        .form-check-input {
            display: flex;
            margin-bottom: 0.5rem;
            align-items: center;
            border: thin solid $gray-color;
            padding: 0.7rem;
            margin-right: 1rem;
            cursor: auto;
            position: relative;
            border-radius: 0.25rem;

            &:checked {
                &:after {
                    position: absolute;
                    content: '';
                    display: block;
                    padding: 0.5rem;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: url('../images/icons/checked_icon.svg') no-repeat center;
                }
            }
        }
    }
}

.button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 2rem;
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;

    .owl-stage {
        position: relative;
        -ms-touch-action: pan-Y;
        touch-action: manipulation;
        -moz-backface-visibility: hidden; /* fix firefox animation glitch */
    }

    .owl-stage:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }

    .owl-stage-outer {
        position: relative;
        overflow: hidden;
        /* fix for flashing background */
        -webkit-transform: translate3d(0px, 0px, 0px);
    }

    .owl-wrapper,
    .owl-item {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
    }

    .owl-item {
        position: relative;
        min-height: 1px;
        float: left;
        -webkit-backface-visibility: hidden;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
    }

    .owl-nav.disabled,
    .owl-dots.disabled {
        display: none;
    }

    .owl-nav .owl-prev,
    .owl-nav .owl-next,
    .owl-dot {
        cursor: pointer;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .owl-nav button.owl-prev,
    .owl-nav button.owl-next,
    button.owl-dot {
        background: none;
        color: inherit;
        border: none;
        font: inherit;
    }

    &.owl-loaded {
        display: block;
    }

    &.owl-loading {
        opacity: 0;
        display: block;
    }

    &.owl-hidden {
        opacity: 0;
    }

    &.owl-refresh .owl-item {
        visibility: hidden;
    }

    &.owl-drag .owl-item {
        touch-action: pan-y;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &.owl-grab {
        cursor: move;
        cursor: grab;
    }

    &.owl-rtl {
        direction: rtl;
    }

    &.owl-rtl .owl-item {
        float: right;
    }
}

/* No Js */
.no-js .owl-carousel {
    display: block;
}

// common carousel styles

.carousel-wrapper {
    .owl-carousel {
        .owl-nav {
            .owl-prev, .owl-next {
                position: absolute;
                top: calc(50% - 2rem);
                width: 4rem;
                height: 4rem;
                background: $white no-repeat center;
                opacity: 0.6;
                transition: all 0.25s linear;

                &:hover {
                    background-color: $red-orange-color;
                    opacity: 1;
                }
            }

            .owl-prev {
                left: 0;
                background-image: url('../images/icons/previous_icon.svg');
            }

            .owl-next {
                right: 0;
                background-image: url('../images/icons/previous_icon.svg');
                transform: rotate(180deg);
            }
        }

        .owl-dots {
            position: absolute;
            left: calc(50% - 15rem);
            bottom: 3rem;
            display: flex;
            justify-content: center;
            width: 30rem;


            .owl-dot {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2rem;
                height: 2.4rem;
                background-color: rgba(0, 0, 0, .5);

                span {
                    width: 0.9rem;
                    height: 0.9rem;
                    background: rgba(255, 255, 255, 0.4);
                    border-radius: 50%;
                }

                &.active {
                    span {
                        background: $white;
                    }
                }

                &:first-child {
                    padding-left: 0.5rem;
                    width: 2.5rem;
                }

                &:last-child {
                    padding-right: 0.5rem;
                    width: 2.5rem;
                }
            }

        }
    }

    .slide-progress {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        max-width: 100%;
        height: 0.5rem;
        background: $red-orange-color;
        z-index: 1;
    }
}


@media (max-width: $breakpoint-medium) {
    .cart-table-wrapper {
        .table-bordered.cart-table {
            thead {
                tr {
                    .cart-header {
                        font-size: 1.2rem;
                        white-space: pre-wrap;
                        text-align: center;
                        padding: 0.25rem;
                        vertical-align: middle;
                    }
                }
            }

            .cart-table-body {
                .basket-item-wrapper {
                    padding: 0;

                    .item-cell {
                        padding: 0;
                    }

                    .item-details-wrapper {
                        .item-details {
                            flex-direction: column;

                            .item-image {
                                figcaption {
                                    h2 {
                                        font-size: 1.2rem;
                                    }
                                }
                            }

                            .item-description {
                                padding: 0.5rem;
                                font-size: 1.2rem;
                                text-align: left;

                                .options-details {
                                    .summary-details {
                                        &::-webkit-details-marker {
                                            display: none;
                                        }
                                    }

                                    .summary-list {
                                        width: 15rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


// custom icons import by mixin:

@mixin customIcon($name, $extension: "svg", $width: 1.9rem, $height: 1.9rem) {
    .custom-icon {
        &.#{$name} {
            width: $width;
            height: $height;
            background-size: auto;
            $url: "../images/icons/#{$name}.#{$extension}";
            background-image: url($url);
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

@include customIcon(search);
@include customIcon(cart, "svg", 2.2rem, 2.2rem);
@include customIcon(facebook_icon, "svg", 2.2rem, 2.2rem);
@include customIcon(gardens_icon, "svg", 4rem, 3.5rem);
@include customIcon(balcony_icon, "svg", 4rem, 3.5rem);
@include customIcon(hotel_and_restaurant_icon, "svg", 4rem, 3.5rem);
@include customIcon(paved_terrace_icon, "svg", 4rem, 3.5rem);
@include customIcon(advertisement_icon, "svg", 4rem, 3.5rem);
@include customIcon(color_palette_icon, "png", 7rem, 7rem);
@include customIcon(uv_protection_icon, "png", 7rem, 7rem);
@include customIcon(fading_protection_icon, "png", 7rem, 7rem);
@include customIcon(windproof_icon, "png", 7rem, 7rem);
@include customIcon(service_and_warranty_icon, "png", 7rem, 7rem);
@include customIcon(arrow_scroll_page_icon, "svg", 3rem, 2rem);
@include customIcon(next_icon, "svg", 0.6rem, 1rem);
@include customIcon(previous_icon, "svg", 0.6rem, 1rem);
@include customIcon(pointer_icon, "svg", 5rem, 5rem);
@include customIcon(pin_icon, "svg", 1.5rem, 2rem);
@include customIcon(umbrella_icon, "svg", 2.5rem, 2.5rem);
@include customIcon(close_icon, "svg", 2.4rem, 2.4rem);
@include customIcon(love_icon, "svg", 1.8rem, 1.6rem);
@include customIcon(comment_icon, "svg", 1.8rem, 1.6rem);
@include customIcon(info_icon, "svg", 1.4rem, 1.4rem);

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: 700;
}
